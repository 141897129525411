// Import the functions you need from the SDKs you need
import { initializeApp, FirebaseOptions } from "firebase/app"
// import { getAnalytics } from "firebase/analytics"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
  firebaseConfig.measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

export const iniFirebase = () => {
  // Initialize Firebase
  initializeApp(firebaseConfig)
  // const app = initializeApp(firebaseConfig)
  // const analytics = getAnalytics(app)
}
