export default function usePageRoutes() {
  const publicPages = {
    authenticate: '/authenticate',
  }

  const privatePages = {
    home: '/dashboard',
    fuelStations: '/dashboard/fuel-stations',
    settings: '/dashboard/settings',
  }

  const getFuelStationPages = (fuelStationUuid: string) => {
    return {
      main: `${privatePages.fuelStations}/${fuelStationUuid}`
    }
  }

  const getPrivatePages = (businessAccountId: string = 'undefined') => {
    return {
      home: `/business/${businessAccountId}`,
      fuelStations: `/business/${businessAccountId}/fuel-stations`,
      insights: `/business/${businessAccountId}/insights`,
      settings: `/business/${businessAccountId}/settings`,
      addFuelStationLocation: `/business/${businessAccountId}/fuel-stations/add-location`
    }
  }

  return {
    getPrivatePages,
    privatePage: {
      ...privatePages,
      getFuelStationPages
    },
    publicPage: {
      ...publicPages,
    }
  }
}
