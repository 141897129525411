import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import LinearProgress from '@mui/material/LinearProgress'

import DashboardContent from './DashboardContent'
import FuelStationReview from './FuelStationReview'

import useCoreApi from '../hooks/useCoreApi'
import Logger from '../services/logger'
import Error40X from './Error40X'
import * as Types from '../types'

import PH_PROVINCES from '../data/ph-provinces.json'
import PH_GAS_STATION_BRANDS from '../data/ph-fuel-stations.json'

export type GetFuelStationResponse = {
  data: {
    result?: Types.FuelStation
  }
}

const logger = new Logger({
  filePath: '@/components/FuelStation'
})

export default function FuelStation() {
  const { getCoreApiClient } = useCoreApi()
  const { fuelStationId } = useParams()

  const [fuelStationNotFound, setFuelStationNotFound] = useState<boolean>(false)
  const [fuelStation, setFuelStation] = useState<Types.FuelStation>()

  useEffect(() => {
    let isSubscribed = true

    /**
     * Get fuel station info on page load
     */
    const effect = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getFuelStationResponse = await coreApi.get(`/admin/fuel-stations/${fuelStationId}`) as GetFuelStationResponse
  
        if (!isSubscribed) {
          return false
        }
  
        if (getFuelStationResponse.data.result) {
          setFuelStation({
            ...getFuelStationResponse.data.result
          })
        }
      } catch (err: any) {
        if (err?.response?.data?.error?.code) {
          logger.error('Failed to get fuel station info', { error: err.response.data.error })

          if (err.response.data.error.code === 'FuelStationNotFoundError') {
            setFuelStationNotFound(true)
          }
        }
      }
    }

    if (!fuelStation) {
      effect()
    }

    return () => {
      isSubscribed = false
    }
  }, [fuelStationId, fuelStation, getCoreApiClient])

  if (fuelStationNotFound) {
    return <Error40X />
  }

  if (!fuelStation) {
    return <DashboardContent>
      <LinearProgress />
    </DashboardContent>
  }

  return <DashboardContent>
    <Box p={4}>
      <Box mb={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <>
            {fuelStation.status === 'REJECTED' &&
              <Tooltip title="This fuel station location has been rejected." arrow>
                <Chip label="Rejected" color="error" size="small" />
              </Tooltip>
            }
          </>
          <>
            {fuelStation.status === 'PENDING_REVIEW' &&
              <Tooltip title="This fuel station location is still in review." arrow>
                <Chip label="Pending Review" color="warning" size="small" />
              </Tooltip>
            }
          </>
          <>
            {fuelStation.status === 'ACTIVE' &&
              <Chip label="Active" color="info" size="small" />
            }
          </>
          <Typography variant="h6">{fuelStation.label}</Typography>
          <Typography variant="body1">{fuelStation.businessName}</Typography>
          <Typography variant="body1">{PH_GAS_STATION_BRANDS.filter(brands => brands.code === fuelStation.brand)[0].name}</Typography>
        </Stack>
      </Box>
      <>
        {fuelStation.status === 'PENDING_REVIEW' &&
          <Box mb={2} maxWidth={450}>
            <Alert severity="warning">
              <Box mb={1}>
                <Typography variant="body2">This Fuel Station Location is still pending reviewed.</Typography>
              </Box>
              <Box>
                <Typography variant="body2">{`Last updated: ${new Date(fuelStation.updatedAt).toDateString()}`}</Typography>
              </Box>
              <>
                {fuelStation?.status === 'PENDING_REVIEW' &&
                  <Box mt={2}>
                    <FuelStationReview businessAccountId={fuelStation.businessAccountId} fuelStationId={fuelStation.uuid} />
                  </Box>
                }
              </>
            </Alert>
          </Box>
        }
      </>

      <Stack direction="row" spacing={2}>
        <Box component={Paper} width={450} p={2}>
          <Box mb={2}>
            <Box mb={2}>
              <Typography color="primary" variant="h6" component="h2">Address Information</Typography>
            </Box>
            <Box mb={2}>
              <Typography color="GrayText" variant="body2">Street</Typography>
              <Typography variant="body1">{fuelStation.addressStreet}</Typography>
            </Box>
            <Box mb={2}>
              <Typography color="GrayText" variant="body2">Barangay</Typography>
              <Typography variant="body1">{fuelStation.addressBarangay}</Typography>
            </Box>
            <Box mb={2}>
              <Typography color="GrayText" variant="body2">City</Typography>
              <Typography variant="body1">{fuelStation.addressCity}</Typography>
            </Box>
            <Box mb={2}>
              <Typography color="GrayText" variant="body2">Zip Code</Typography>
              <Typography variant="body1">{fuelStation.addressPostalCode}</Typography>
            </Box>
            <Box mb={2}>
              <Typography color="GrayText" variant="body2">Province</Typography>
              <Typography variant="body1">{PH_PROVINCES.filter(province => province.code === fuelStation.addressProvince)[0].name}</Typography>
            </Box>
            <Box>
              <Typography color="GrayText" variant="body2">Country</Typography>
              <Typography variant="body1">{fuelStation.addressCountry}</Typography>
            </Box>
          </Box>
          <Box mb={1}>
            <Typography color="primary" variant="body1" component="h2">Map GPS Coordinates</Typography>
          </Box>
          <Box mb={2}>
            <Stack direction="row" spacing={2}>
              <Box>
                <Typography color="GrayText" variant="body2">Latitude</Typography>
                <Typography variant="body1">{fuelStation.addressLatitude}</Typography>
              </Box>
              <Box>
                <Typography color="GrayText" variant="body2">Longitude</Typography>
                <Typography variant="body1">{fuelStation.addressLongitude}</Typography>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Button variant="outlined" color="secondary" component="a" href={`https://maps.google.com/?q=${fuelStation.addressLatitude},${fuelStation.addressLongitude}`} target="_blank" rel="noreferrer">Preview Map</Button>
          </Box>
        </Box>
        <Box component={Paper} width={450} p={2}>
          <Box mb={2}>
            <Typography color="primary" variant="h6" component="h2">Business Information</Typography>
          </Box>
          <Box mb={2}>
            <Typography color="GrayText" variant="body2">Business Name</Typography>
            <Typography variant="body1">{fuelStation.businessName}</Typography>
          </Box>
          <Box mb={2}>
            <Typography color="GrayText" variant="body2">Brand</Typography>
            <Typography variant="body1">{`${PH_GAS_STATION_BRANDS.filter(brands => brands.code === fuelStation.brand)[0].name} (${fuelStation.brand})`}</Typography>
          </Box>
          <Box mb={2}>
            <Typography color="GrayText" variant="body2">Label</Typography>
            <Typography variant="body1">{fuelStation.label}</Typography>
          </Box>
          <Box mb={2}>
            <Typography color="GrayText" variant="body2">Primary Contact Email</Typography>
            <Typography variant="body1">{fuelStation.primaryContactEmail}</Typography>
          </Box>
          <Box mb={2}>
            <Typography color="GrayText" variant="body2">Primary Contact Phone</Typography>
            <Typography variant="body1">{fuelStation.primaryContactPhoneNumber}</Typography>
          </Box>
          <Box mb={2}>
            <Typography color="GrayText" variant="body2">Is the location a Dealer Operated Franchise?</Typography>
            <Typography variant="body1">{fuelStation.isDealerOperatedFranchise ? 'YES' : 'NO'}</Typography>
          </Box>
          <>
            {fuelStation.isDealerOperatedFranchise &&
              <Box>
                <Typography color="GrayText" variant="body2">Franchise Expiration</Typography>
                <>
                  {(fuelStation.franchiseExpirationMonth !== undefined && fuelStation.franchiseExpirationYear !== undefined) &&
                    <Typography variant="body1">{`${['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'][fuelStation.franchiseExpirationMonth]} ${fuelStation.franchiseExpirationYear}`}</Typography>
                  }
                </>
              </Box>
            }
          </>
        </Box>
      </Stack>
    </Box>
  </DashboardContent>
}
