import React from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

// import Logger from '../services/logger'
import DashboardContent from './DashboardContent'

// const logger = new Logger({
//   filePath: '@/components/DashboardHome'
// })

export default function DashboardHome() {
  // const [something, setSomething] = useState(false)

  return <DashboardContent>
    <Box p={4}>
      <Stack direction="column" spacing={2}>
        <Typography variant="h5" component="h1">Welcome to the Fewlsy Admin dashboard</Typography>
        <Alert severity="warning">
          <Typography>This dashboard is strictly for Fewlsy Staff only. Unauthorized access to this application is prohibited and punishable by law.</Typography>
        </Alert>
      </Stack>
    </Box>
  </DashboardContent>
}
