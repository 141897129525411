import React, { useState } from 'react'
import styled from 'styled-components'
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { Link, useNavigate } from 'react-router-dom'
import errcode from 'err-code'
import {
  Alert,
  Box,
  Button,
  Container,
  Typography
} from '@mui/material'
import { Theme } from '@mui/material/styles'

import Logger from '../services/logger'
import usePageRoutes from '../hooks/usePageRoutes'
import useUser from '../hooks/useUser'
import useCoreApi from '../hooks/useCoreApi'
import { UserApiResponse } from '../types'

import '../static/styles/main.css'
import imgAppIcon from '../static/images/app-icon.svg'
import imgGoogleIcon from '../static/images/google-icon.png'

const logger = new Logger({
  filePath: '@/screens/AuthRegister'
})

type StyledTheme = {
  theme: Theme
}

type GoogleBtnProps = {
  children: React.ReactNode
}

type FormErrors = {
  submit?: 'USER_NOT_FOUND' | 'REQUEST_FAILED' | 'UNAUTHORIZED_USER' | 'PROVIDER_SIGNIN_FAILED'
}

type ProviderUserInfo = {
  uid: string
  email: string
  isEmailVerified: boolean
  displayName: string
}

export type CreateUserResponse = {
  data: {
    result?: UserApiResponse
  }
}

const AuthContainer = styled(Container)`
  ${({ theme }: StyledTheme) => `
    &.MuiContainer-root {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `}
`

export default function LoginScreen() {
  const navigate = useNavigate()
  const [isProviderAuthenticating, setIsProviderAuthenticating] = useState(false)
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const pageRoutes = usePageRoutes()
  const { loginUser } = useUser()
  const { getCoreApiClient } = useCoreApi()
  const [providerUserInfo, setProviderUserInfo] = useState<ProviderUserInfo>()

  const handleOnGoogleRegisterBtnClick = async () => {
    try {
      setIsProviderAuthenticating(true)
      setFormErrors({})

      const auth = getAuth()
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({
        prompt: 'select_account'
      })
      const result = await signInWithPopup(auth, provider)

      if (!result.user) {
        throw errcode(new Error('Registration response does not include `user` property'), 'ProviderSignInFaultyUserError')
      }

      if (!result.user.email) {
        throw errcode(new Error('Google sign in did not return an email address'), 'ProviderSignInFaultyEmailError')
      }

      setProviderUserInfo({
        uid: result.user.uid,
        email: result.user.email,
        isEmailVerified: result.user.emailVerified,
        displayName: result.user.displayName || `user${Date.now()}`,
      })

      await loginUser(result.user.uid)
  
      navigate(pageRoutes.privatePage.home, { replace: true })
    } catch (err: any) {
      const errCode = err.response?.data?.error?.code || err.code
      const errMessage = err.response?.data.error.message || err.message

      logger.error('Login failed', {
        errCode,
        errMessage,
        err,
      })

      let formErrorsFound: FormErrors = {}

      if (errCode === 'UserNotFoundError') {
        formErrorsFound.submit = 'USER_NOT_FOUND'
      } else if (errCode === 'UnauthorizedUserError') {
        formErrorsFound.submit = 'UNAUTHORIZED_USER'
      } else {
        formErrorsFound.submit = 'REQUEST_FAILED'
      }

      setFormErrors({ ...formErrorsFound })
      setIsProviderAuthenticating(false)
    }
  }

  const handleOnFewlsyRegisterBtnClick = async () => {
    try {
      let email = ''
      let displayName = ''
      let providerUid = ''

      if (!providerUserInfo) {
        setIsProviderAuthenticating(true)

        const auth = getAuth()
        const provider = new GoogleAuthProvider()
        provider.setCustomParameters({
          prompt: 'select_account'
        })
        const result = await signInWithPopup(auth, provider)

        if (result.user && result.user.email) {
          email = result.user.email
          displayName = result.user.displayName || `user${Date.now()}`
          providerUid = result.user.uid
        }
      }
      
      if (providerUserInfo) {
        email = providerUserInfo.email
        displayName = providerUserInfo.displayName
        providerUid = providerUserInfo.uid
      }

      if (email.length < 1 || displayName.length < 1 || providerUid.length < 1) {
        throw errcode(new Error('Failed to get required basic info from google sign in'), 'ProviderSignInFailedError')
      }

      const coreApi = await getCoreApiClient()
      const createUserResponse = await coreApi.post('/users', {
        email,
        displayName,
        country: 'PH',
        provider: 'GOOGLE',
        providerUid,
      }, {
        params: {
          type: 'personal'
        }
      }) as CreateUserResponse

      if (!createUserResponse || !createUserResponse.data || !createUserResponse.data.result) {
        throw errcode(new Error('Missing result in CreateUserResponse'), 'CreateUserFailed')
      }

      const createUserResult = createUserResponse.data.result

      await loginUser(createUserResult.providerUid)

      navigate(pageRoutes.privatePage.home, { replace: true })
    } catch (err: any) {
      const errCode = err.response?.data?.error?.code || err.code
      const errMessage = err.response?.data.error.message || err.message

      logger.error('Login failed', {
        errCode,
        errMessage,
        err,
      })

      let formErrorsFound: FormErrors = {}
      if (errCode === 'ProviderSignInFailedError') {
        formErrorsFound.submit = 'PROVIDER_SIGNIN_FAILED'
      } else if (errCode === 'UserNotFoundError') {
        formErrorsFound.submit = 'USER_NOT_FOUND'
      } else if (errCode === 'UnauthorizedUserError') {
        formErrorsFound.submit = 'UNAUTHORIZED_USER'
      } else {
        formErrorsFound.submit = 'REQUEST_FAILED'
      }

      setFormErrors({ ...formErrorsFound })
      setIsProviderAuthenticating(false)
    }
  }

  const GoogleBtn = ({ children }: GoogleBtnProps) => {
    return <button className="appui__btn appui__auth-btn" onClick={handleOnGoogleRegisterBtnClick}>{ children }</button>
  }

  if (isProviderAuthenticating) {
    return <AuthContainer>
      <Box mb={3}>
        <img src={imgAppIcon} width={64} height={64} alt="Fewlsy Icon" />
      </Box>
      <Box>
        <Typography variant="h5">Authenticating</Typography>
      </Box>
    </AuthContainer>
  }

  return <AuthContainer>
    <Box mb={5}>
      <img src={imgAppIcon} width={128} height={128} alt="Fewlsy Icon" />
    </Box>
    <Box mb={5}>
      <Typography variant="h5">Log in to your account</Typography>
    </Box>
    <>
      {formErrors.submit === 'USER_NOT_FOUND' && providerUserInfo &&
        <Box mb={5}>
          <Alert severity="error">
            <Box mb={1}>It looks like you haven't signed up for a Fewlsy Account yet.</Box>
            <Box>Would you like to <Button size="small" variant="contained" color="secondary" onClick={handleOnFewlsyRegisterBtnClick}>Register</Button></Box>
          </Alert>
        </Box>
      }
    </>
    <>
      {formErrors.submit === 'UNAUTHORIZED_USER' &&
        <Box mb={5}>
          <Alert severity="error">
            Your account is not authorized to access this page.
          </Alert>
        </Box>
      }
    </>
    <>
      {formErrors.submit === 'REQUEST_FAILED' &&
        <Box mb={5}>
          <Alert severity="error">
            Uh-oh! Authentication failed. Please refresh the tab and try again.
          </Alert>
        </Box>
      }
    </>
    <>
      {formErrors.submit === 'PROVIDER_SIGNIN_FAILED' &&
        <Box mb={5}>
          <Alert severity="error">
            Uh-oh! Your Google sign-in did not return an email address. Try signing in with a Google account that has an email address.
          </Alert>
        </Box>
      }
    </>
    <Box mb={5}>
      <GoogleBtn>
        <img src={imgGoogleIcon} width={32} height={32} alt="Continue with Google" />
        <span className="appui__auth-btn-text">Continue with Google</span>
      </GoogleBtn>
    </Box>
    <Box>
      <Typography variant="body1">Don't have an account? <Button variant="text" color="primary" component={Link as any} to="/register">Register</Button></Typography>
    </Box>
  </AuthContainer>
}
