import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

import DashboardContent from './DashboardContent'

import Logger from '../services/logger'
import useCoreApi from '../hooks/useCoreApi'
import * as Types from '../types'

export type GetFuelStationsResponse = {
  data: {
    result?: Types.FuelStation[],
    totalRowCount?: number,
  }
}

const logger = new Logger({
  filePath: '@/components/DashboardFuelStations'
})

export default function DashboardFuelStations() {
  const { getCoreApiClient } = useCoreApi()

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [fuelStations, setFuelStations] = useState<Types.FuelStation[]>()
  const [page, setPage] = useState<number>(0)
  const [totalRowCount, setTotalRowCount] = useState<number>()

  const filterFuelStationIdRef = useRef<TextFieldProps>(null)
  const filterBusinessAccountIdRef = useRef<TextFieldProps>(null)
  const filterStatusRef = useRef<TextFieldProps>(null)

  const handleOnFilterClick = async (pageNumber: number) => {
    try {
      setTableLoading(true)

      if (!filterFuelStationIdRef.current || !filterBusinessAccountIdRef.current || !filterStatusRef.current) {
        logger.error('Reference to form field is missing', {
          refs: [filterFuelStationIdRef.current, filterBusinessAccountIdRef.current, filterStatusRef.current]
        })
        throw new Error('Reference to form field is missing')
      }

      const filterFuelStationId = filterFuelStationIdRef.current.value as string
      const filterBusinessAccountId = filterBusinessAccountIdRef.current.value as string
      const filterStatus = filterStatusRef.current.value as string

      const coreApi = await getCoreApiClient()
      const getFuelStationResponse = await coreApi.get('/admin/fuel-stations', {
        params: {
          status: filterStatus || 'ANY',
          fuelStationId: filterFuelStationId.length > 0 ? filterFuelStationId : undefined,
          businessAccountId: filterBusinessAccountId.length > 0 ? filterBusinessAccountId : undefined,
          page: pageNumber,
        }
      }) as GetFuelStationsResponse

      if (getFuelStationResponse.data.result) {
        setFuelStations([...getFuelStationResponse.data.result])
      }

      if (getFuelStationResponse.data.totalRowCount) {
        setTotalRowCount(getFuelStationResponse.data.totalRowCount)
      }

      setTableLoading(false)
    } catch (err) {
      logger.error('DashboardFuelStations failed to filter table on click', { err })
    }
  }

  const handleOnPageChange = (event: unknown, newPage: number) => {
    setPage(newPage)
    handleOnFilterClick(newPage)
  }

  useEffect(() => {
    let isSubscribed = true

    const effect = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getFuelStationResponse = await coreApi.get('/admin/fuel-stations', {
          params: {
            status: 'ANY',
            page: 0,
          },
        }) as GetFuelStationsResponse
  
        if (!isSubscribed) {
          return false
        }
  
        if (getFuelStationResponse.data.result) {
          setFuelStations([...getFuelStationResponse.data.result])
        }

        if (getFuelStationResponse.data.totalRowCount) {
          setTotalRowCount(getFuelStationResponse.data.totalRowCount)
        }
      } catch (err) {
        logger.error('DashboardFuelStations failed to initialize', { err })
      }
    }

    if (!fuelStations) {
      effect()
    }

    return () => {
      isSubscribed = false
    }
  }, [fuelStations, getCoreApiClient])

  if (!fuelStations) {
    return <DashboardContent>
      <LinearProgress />
    </DashboardContent>
  }

  return <DashboardContent>
    <Box p={4}>
      <Stack direction="column">
        <Box maxWidth={1440}>
          <TableContainer component={Paper}>
            <Box p={2}>
              <Stack direction="row" spacing={2}>
                <TextField
                  size="small"
                  color="secondary"
                  label="Fuel Station ID"
                  variant="outlined"
                  inputRef={filterFuelStationIdRef}
                />
                <TextField
                  size="small"
                  color="secondary"
                  label="Business Account ID"
                  variant="outlined"
                  inputRef={filterBusinessAccountIdRef}
                />
                <TextField
                  size="small"
                  color="secondary"
                  label="Status"
                  variant="outlined"
                  defaultValue="ANY"
                  select
                  inputRef={filterStatusRef}
                >
                  <MenuItem value="ANY">ANY</MenuItem>
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="PENDING_REVIEW">PENDING_REVIEW</MenuItem>
                  <MenuItem value="REJECTED">REJECTED</MenuItem>
                </TextField>
                <Button size="small" color="secondary" variant="contained" onClick={() => handleOnFilterClick(0)}>Filter</Button>
              </Stack>
            </Box>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Label</TableCell>
                  <TableCell align="right">Fuel Station ID</TableCell>
                  <TableCell align="right">Business Account ID</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  fuelStations.map((fuelStation) => {
                    return <TableRow key={`${fuelStation.businessAccountId}-${fuelStation.uuid}`}>
                      <TableCell>{fuelStation.label}</TableCell>
                      <TableCell align="right">{fuelStation.uuid}</TableCell>
                      <TableCell align="right">{fuelStation.businessAccountId}</TableCell>
                      <TableCell align="right">
                        <>
                          {fuelStation.status === 'REJECTED' &&
                            <Tooltip title="This fuel station location has been rejected." arrow>
                              <Chip label="Rejected" color="error" size="small" />
                            </Tooltip>
                          }
                        </>
                        <>
                          {fuelStation.status === 'PENDING_REVIEW' &&
                            <Tooltip title="This fuel station location is still in review." arrow>
                              <Chip label="Pending Review" color="warning" size="small" />
                            </Tooltip>
                          }
                        </>
                        <>
                          {fuelStation.status === 'ACTIVE' &&
                            <Chip label="Active" color="info" size="small" />
                          }
                        </>
                      </TableCell>
                      <TableCell align="right">
                        <Button color="secondary" component={Link} to={`/dashboard/fuel-stations/${fuelStation.uuid}`}>Open</Button>
                      </TableCell>
                    </TableRow>
                  })
                }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={totalRowCount || 0}
              rowsPerPage={10}
              page={page}
              onPageChange={handleOnPageChange}
              onRowsPerPageChange={() => false}
              showFirstButton={true}
            />
            <>
              {tableLoading &&
                <LinearProgress />
              }
            </>
          </TableContainer>
        </Box>
      </Stack>
    </Box>
  </DashboardContent>
}
