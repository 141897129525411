import React, { useReducer, Dispatch } from 'react';
import { User } from '../types/index'

export enum UserContextActions {
  SET_USER_INFO,
  LOGOUT_USER
}

type ContextState = {
  currentUser: null | User
}

type ContextAction = {
  type: UserContextActions.SET_USER_INFO
  payload: {
    currentUser: User
  }
} | { type: UserContextActions.LOGOUT_USER }

type ContextUser = {
  UserState: ContextState;
  UserDispatch: Dispatch<ContextAction>
}

type AppProviderProps = {
  children: React.ReactNode
}

const initialState: ContextState = {
  currentUser: null
}

const reducer = (state: ContextState, action: ContextAction): ContextState => {
  switch (action.type) {
    case UserContextActions.SET_USER_INFO:
      return { ...state, currentUser: action.payload.currentUser }
    case UserContextActions.LOGOUT_USER:
      return { ...state, currentUser: null }
    default:
      return { ...state }
  }
};

export const UserContext = React.createContext({} as ContextUser);

export const UserProvider: React.FC<AppProviderProps> = (props) => {
  const [UserState, UserDispatch] = useReducer(reducer, initialState)

  return (
    <UserContext.Provider value={{ UserState, UserDispatch }}>
      {props.children}
    </UserContext.Provider>
  );
}
