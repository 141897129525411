import { useContext } from 'react'
import { getAuth, signOut } from 'firebase/auth'
import errcode from 'err-code'

import { UserContext, UserContextActions } from '../contexts/User'
import usePageRoutes from './usePageRoutes'
import useCoreApi from './useCoreApi'
import { UserApiResponse, User } from '../types'

import Logger from '../services/logger'

export type GetUserResponse = {
  data: {
    result?: UserApiResponse
  }
}

const logger = new Logger({
  filePath: '@/hooks/useUser'
})

export const useUser = () => {
  const { UserState, UserDispatch } = useContext(UserContext)
  const { currentUser } = UserState
  const pageRoutes = usePageRoutes()
  const { getCoreApiClient } = useCoreApi()

  const loginUser = async (providerUid: string) => {
    const coreApi = await getCoreApiClient()
    const getUserResponse = await coreApi.get('/users', {
      params: {
        provider: 'GOOGLE',
        providerUid,
      }
    }) as GetUserResponse

    if (!getUserResponse || !getUserResponse.data || !getUserResponse.data.result) {
      throw errcode(new Error('Unregistered email address'), 'UserNotFoundError')
    }

    const getUserResult = getUserResponse.data.result

    const payload: User = {
      uuid: getUserResult.uuid,
      businessAccount: getUserResult.businessAccount,
      email: getUserResult.email,
      isEmailVerified: getUserResult.isEmailVerified,
      displayName: getUserResult.displayName ? getUserResult.displayName : '',
      termsAcceptedAt: new Date(getUserResult.termsAcceptedAt).toISOString(),
      country: getUserResult.country,
      provider: getUserResult.provider,
      providerUid: getUserResult.providerUid,
      status: getUserResult.status,
    }

    if (!getUserResult.isFewlsyStaff) {
      throw errcode(new Error('Unauthorized registered user'), 'UnauthorizedUserError')
    }

    payload.isFewlsyStaff = getUserResult.isFewlsyStaff

    UserDispatch({
      type: UserContextActions.SET_USER_INFO,
      payload: {
        currentUser: payload
      },
    })

    return payload
  }

  const getCurrentAuthUser = () => {
    return {
      currentAuthUser: getAuth().currentUser
    }
  }

  const logoutUser = async () => {
    try {
      // logout firebase
      const auth = getAuth()
      await signOut(auth)
      
      // redirect to login
      // all context will disappear. so no need to reset context.
      window.location.replace(pageRoutes.publicPage.authenticate)
    } catch (err) {
      logger.error('Failed to logout user', { err })
      return
    }
  }

  return {
    getCurrentAuthUser,
    logoutUser,
    loginUser,
    currentUser,
  }
}

export default useUser
