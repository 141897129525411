type LoggerConstructorParams = {
  filePath: string
}

class Logger {
  constructor (params: LoggerConstructorParams) {
    this.filePath = params.filePath
  }

  private filePath: string

  error(msg: string, data?: object) {
    console.error(`Error in ${this.filePath}:`, msg, data)
  }

  log(msg: string, data?: object) {
    console.log(`Log message in ${this.filePath}:`, msg, data)
  }

  warn(msg: string, data?: object) {
    console.warn(`Warning in ${this.filePath}:`, msg, data)
  }
}

export default Logger
