import React, { useEffect, useState } from 'react'
import { Outlet, Navigate, useParams } from 'react-router-dom'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { LinearProgress } from '@mui/material'

import Error40X from '../components/Error40X'

import { useUser } from '../hooks/useUser'

export default function PrivateScreens() {
  const { currentUser, loginUser, logoutUser } = useUser()
  const { businessAccountId } = useParams()
  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  useEffect(() => {
    let isSubscribed = true
    const reauthenticateUser = async (providerId: string) => {
      try {
        await loginUser(providerId)
        
        if (!isSubscribed) {
          return false
        }
        
        setIsInitialized(true)
      } catch (err) {
        logoutUser()
      }
    }

    const ini = () => {
      if (!isSubscribed) {
        return false
      }

      const auth = getAuth()

      onAuthStateChanged(auth, (user) => {
        if (!isSubscribed) {
          return false
        }

        if (isSubscribed && user) {
          reauthenticateUser(user.uid || '')
          return
        }

        setIsInitialized(true)
      });
    }

    if (isSubscribed && !isInitialized) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [isInitialized, loginUser, businessAccountId, logoutUser])

  if (!isInitialized) {
    return <div className="app">
      <LinearProgress />
    </div>
  }

  if (currentUser === null) {
    return <Navigate to="/authenticate" replace={true} />
  }

  if (!currentUser?.isFewlsyStaff) {
    return <Error40X />
  }

  return <div className="app">
    <Outlet />
  </div>
}
