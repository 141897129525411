import React, { useState, useRef } from 'react'
import errcode from 'err-code'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField, { TextFieldProps} from '@mui/material/TextField'

import useCoreApi from '../hooks/useCoreApi'
import * as Types from '../types'

import Logger from '../services/logger'

const logger = new Logger({
  filePath: '@/components/FuelStationReview'
})

type PutFuelStationLocationInfoReviewResponse = {
  data: {
    result?: Types.FuelStation
  }
}

type FormErrors = {
  notes?: 'FIELD_REQUIRED'
  submit?: 'REQUEST_FAILED'
}

type FuelStationReviewProps = {
  businessAccountId: string
  fuelStationId: string
}

export default function FuelStationReview(props: FuelStationReviewProps) {
  const { fuelStationId } = props

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const notesRef = useRef<TextFieldProps>(null)
  const [switches, setSwitches] = useState<Types.LocationInfoReviewChecks>({
    isRequiredFieldsComplete: false,
    isAddressLatLongCorrect: false,
    hasBusinessPermit: false,
    hasDtiPermit: false,
    hasFranchiseAgreement: false,
    isFormAndDocumentInfoMatch: false,
  })
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [isApproveDisabled, setIsApproveDisabled] = useState(true)
  const [formErrors, setFormErrors] = useState<FormErrors>({})

  const { getCoreApiClient } = useCoreApi()

  const handleSwitchChange = (key: keyof Types.LocationInfoReviewChecks, event: React.ChangeEvent<HTMLInputElement>) => {
    const newSwitches = {
      ...switches,
      [key]: event.target.checked
    }

    setSwitches({ ...newSwitches })

    let hasUncheckedRequirement = false
    if (newSwitches.isRequiredFieldsComplete === false ||
      newSwitches.isAddressLatLongCorrect === false ||
      newSwitches.hasBusinessPermit === false ||
      newSwitches.hasDtiPermit === false ||
      newSwitches.hasFranchiseAgreement === false ||
      newSwitches.isFormAndDocumentInfoMatch === false
    ) {
      hasUncheckedRequirement = true
    }

    setIsApproveDisabled(hasUncheckedRequirement)
  }

  const handleOnSubmit = async (type: 'APPROVE' | 'REJECT') => {
    try {
      setIsFormDisabled(true)

      if (!notesRef.current) {
        logger.error('Reference to form field is missing', {
          refs: [
            notesRef.current,
          ]
        })
        throw new Error('Reference to form field is missing')
      }


      const notes = notesRef.current.value as string

      let formErrorsFound: FormErrors = {}

      if (type === 'REJECT') {
        if (notes.trim().length < 1) {
          formErrorsFound.notes = 'FIELD_REQUIRED'          
        }
      }

      if (Object.keys(formErrorsFound).length > 0) {
        setIsFormDisabled(false)
        throw errcode(new Error('Form validation errors found'), 'FormSubmitError', { formErrorsFound })
      }

      // No errors found
      setFormErrors({})

      const coreApi = await getCoreApiClient()
      const putFuelStationLocationInfoReviewResponse = await coreApi.put(`/admin/fuel-stations/${fuelStationId}/location-info-review/${type}`, {
        checks: {
          ...switches,
        },
        notes,
      }) as PutFuelStationLocationInfoReviewResponse

      if (!putFuelStationLocationInfoReviewResponse?.data?.result?.locationInfoReviewResult) {
        throw errcode(new Error('Failed to review fuel station location info'), 'FormSubmitError')
      }

      window.location.reload()
    } catch (err: any) {
      const errCode = err.response?.data?.error?.code || err.code
      const errMessage = err.response?.data?.error?.message || err.message

      logger.error('Submit review failed', { errMessage, errCode })

      let formErrorsFound: FormErrors = {}

      if (errCode === 'FormSubmitError') {
        setFormErrors({
          ...err.formErrorsFound
        })
        setIsFormDisabled(false)
        return
      }

      formErrorsFound.submit = 'REQUEST_FAILED'

      setFormErrors({ ...formErrorsFound })
      setIsFormDisabled(false)
    }
  }

  const handleOnDialogOpen = () => {
    setIsDialogOpen(true)
  }

  const handleOnDialogClose = (e: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!isFormDisabled) {
      if (reason !== 'backdropClick') {
        setIsDialogOpen(false)
        setFormErrors({})
      }
    }
  }

  const handleOnCancelClick = () => {
    setIsDialogOpen(false)
    setFormErrors({})
  }

  return <Box>
    <Box>
      <Button variant="contained" color="secondary" onClick={handleOnDialogOpen}>Review</Button>
    </Box>
    <Dialog onClose={handleOnDialogClose} open={isDialogOpen}>
      <DialogTitle>Review Fuel Station</DialogTitle>
      <DialogContent>
        <Box width={500}>
          <FormControlLabel
            control={<Switch
              checked={switches.isRequiredFieldsComplete}
              onChange={(e) => handleSwitchChange('isRequiredFieldsComplete', e)}
              disabled={isFormDisabled}
            />}
            label="Is all the required fields filled up?"
          />
          <FormControlLabel
            control={<Switch
              checked={switches.isAddressLatLongCorrect}
              onChange={(e) => handleSwitchChange('isAddressLatLongCorrect', e)}
              disabled={isFormDisabled}
            />}
            label="Is the latitude longitude coordinates correct?"
          />
          <FormControlLabel
            control={<Switch
              checked={switches.hasBusinessPermit}
              onChange={(e) => handleSwitchChange('hasBusinessPermit', e)}
              disabled={isFormDisabled}
            />}
            label="Has submitted Business Permit?"
          />
          <FormControlLabel
            control={<Switch
              checked={switches.hasDtiPermit}
              onChange={(e) => handleSwitchChange('hasDtiPermit', e)}
              disabled={isFormDisabled}
            />}
            label="Has submitted DTI Permit?"
          />
          <FormControlLabel
            control={<Switch
              checked={switches.hasFranchiseAgreement}
              onChange={(e) => handleSwitchChange('hasFranchiseAgreement', e)}
              disabled={isFormDisabled}
            />}
            label="Has submitted franchise agreement or similar document?"
          />
          <FormControlLabel
            control={<Switch
              checked={switches.isFormAndDocumentInfoMatch}
              onChange={(e) => handleSwitchChange('isFormAndDocumentInfoMatch', e)}
              disabled={isFormDisabled}
            />}
            label="Does the form info and document info match?"
          />
          <Box mt={2}>
            <TextField
              fullWidth
              multiline
              maxRows={5}
              size="small"
              label="Notes"
              variant="outlined"
              disabled={isFormDisabled}
              inputRef={notesRef}
              error={formErrors.notes === 'FIELD_REQUIRED'}
              helperText={formErrors.notes === 'FIELD_REQUIRED' ? 'Field required' : undefined}
            />
          </Box>
        </Box>
      </DialogContent>
      <Box display={isFormDisabled ? 'none' : undefined} pb={2} px={2}>
        <Stack direction="row" justifyContent="space-between">
          <Button variant="outlined" color="error" disabled={isFormDisabled} onClick={handleOnCancelClick}>Cancel</Button>
          <Box>
            <Stack direction="row" justifyContent="flex-end" spacing={3}>
              <Button variant="contained" color="error" disabled={isFormDisabled} onClick={() => handleOnSubmit('REJECT')}>Reject</Button>
              <Button variant="text" color="primary" disabled={isApproveDisabled} onClick={() => handleOnSubmit('APPROVE')}>Approve</Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <>
        {formErrors.submit &&
          <Box>
            <Alert severity="error">Failed to submit fuel station location info review</Alert>
          </Box>
        }
      </>
    </Dialog>
  </Box>
}