import styled from 'styled-components'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Theme } from '@mui/material/styles'

import imgError40XGraphic from '../static/images/error-40X-graphic.png'

type StyledTheme = {
  theme: Theme,
}

type Error40XProps = {
  type?: 'NO_BUSINESS_ACCOUNT'
}

const AccessForbiddenBox = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `}
`

export default function Error40X(props: Error40XProps) {
  if (props.type === 'NO_BUSINESS_ACCOUNT') {
    return <AccessForbiddenBox>
      <Box mb={2}>
        <img src={imgError40XGraphic} height={256} width={256} alt="Error 40X" />
      </Box>
      <Box mb={2} textAlign="center">
        <Box mb={2}>
          <Typography variant="h4" component="p">Sorry, we can't open the page for you.</Typography>
        </Box>
        <Typography variant="h6" component="p">It looks like you are not linked to a Fewlsy Business account.</Typography>
        <Typography variant="h6" component="p">Please ask your Fewlsy Business account administrator to send you an invite.</Typography>
      </Box>
      <Typography variant="body1" component="p">If you have any questions, please <a href="#TODO">contact support</a>.</Typography>
    </AccessForbiddenBox>
  }

  return <AccessForbiddenBox>
    <Box mb={2}>
      <img src={imgError40XGraphic} height={256} width={256} alt="Error 40X" />
    </Box>
    <Box mb={2} textAlign="center">
      <Typography variant="h4" component="p">Sorry, we can't open the page for you.</Typography>
      <Typography variant="h6" component="p">Either the page doesn't exist, no longer exists, or you don't have permission to access it.</Typography>
    </Box>
    <Typography variant="body1" component="p">If you have any questions, please <a href="#TODO">contact support</a>.</Typography>
  </AccessForbiddenBox>
}