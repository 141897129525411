import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from '@mui/material'
import { Theme } from '@mui/material/styles'
import styled from 'styled-components'

type StyledTheme = {
  theme: Theme
}

const MainContainer = styled(Container)`
  ${({ theme }: StyledTheme) => `
    &.MuiContainer-root {
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `}
`

export default function PublicScreen() {
  return <MainContainer>
    <Outlet />
  </MainContainer>
}
