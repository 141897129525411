import React from 'react'
import styled from 'styled-components'

import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles'

type StyledTheme = {
  theme: Theme,
}

type ComponentProps = {
  children: React.ReactNode
}

const DashboardContentBox = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {}
  `}
`

export default function DashboardContent(props: ComponentProps) {
  const { children } = props

  return <DashboardContentBox>
    { children }
  </DashboardContentBox>
}
